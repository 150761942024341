<template>
    <div class="d-flex align-items-center justify-content-between my-2">
        <div class="d-flex align-items-center">
            <div class="square" :style="{ backgroundColor: source.acked ? '#757575' : source.color }"></div>
            <div class="pl-2">
                <div class="date" :style="{ fontSize: (12*$store.state.fontScale) + 'px', lineHeight: (12*$store.state.fontScale) + 'px' }">{{source.date}}</div>
                <div class="msg" :style="{ fontSize: (14*$store.state.fontScale) + 'px', lineHeight: (14*$store.state.fontScale) + 'px' }">{{source.msg}}</div>
            </div>
        </div>
        <div class="pl-1">
            <button class="btn btn-sm btn-warning rounded" v-if="!source.acked" :style="{ fontSize: (12*$store.state.fontScale) + 'px' }" @click="ack">Reconhecer</button>
            <span v-else :style="{ fontSize: (12*$store.state.fontScale) + 'px' }">Reconhecido</span>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import api from '@/api'
import { verifyPermitions } from '@/helpers/auth';

export default {
    props: {
        index: {
            // index of current item
            type: Number,
        },
        source: {
            // color: {
            //     type: String,
            //     default: 'gray',
            // },
            // date: String,
            // msg: String,
            // acked: {
            //     type: Boolean,
            //     default: false,
            // },
            type: Object,
            default() {
                return {};
            },
        },
    },
    methods: {
        ack() {
            this.$swal.fire({
                title: 'Você tem certeza?',
                text: `Deseja confirmar o reconhecimento deste alarme?`,
                type: 'warning',
                showCancelButton: true,
                reverseButtons: true,
                focusCancel: true,
                confirmButtonColor: '#2e7d32',
                confirmButtonText: 'Reconhecer',
                cancelButtonText: 'Cancelar',
                allowOutsideClick: () => !this.$swal.isLoading(),
                target: this.$el.parentElement,
            }).then(async res => {
                if (res.value) {
                    if (!(await verifyPermitions.call(this, 'rw_rec_alarme'))) return;
                    await axios.get(api.v1.alarme.reconhecer(this.source.id));
                    this.source.acked = true;
                }
            });
        }
    }
}
</script>
<style scoped>
    .square {
        width: 6px;
        min-width: 6px;
        align-self: stretch;
    }
    .date {
        color: #333;
        margin-top: 2px;
        margin-bottom: 4px;
    }
    .msg {
        white-space: pre-line;
    }
</style>
