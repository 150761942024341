<template>
    <div class="map-container">

        <object ref="mapa" id="mapa-sinotico-zoom" :data="mapaUrl" type="image/svg+xml" @load="onLoad"></object>
        <div class="d-flex justify-content-start flex-column controls-left align-items-center">
            <div class="d-flex justify-content-start flex-column align-items-center">
                <button
                    class="btn rounded-circle p-1"
                    data-toggle="tooltip"
                    data-placement="right"
                    title="Voltar para a tela geral"
                    @click="zoom = 0; resize(); $emit('zoom', 0)"
                >
                    <HomeIcon />
                </button>
                <div class="d-flex justify-content-start flex-column align-items-center escala">
                    <div class="m-0">Escala</div>
                    <input v-model="userScale" :title="`Escala dos concentradores: ${Math.round(userScale * 100)}%`" type="range" orient="vertical" min="0.5" max="5" step="0.1">
                    <div class="m-0">{{Math.round(userScale * 100)}}%</div>
                </div>
            </div>

            <div class="d-flex justify-content-start flex-column controls-far-left align-items-center">
                <button
                    class="btn rounded-circle p-1"
                    data-toggle="tooltip"
                    data-placement="right"
                    title="Visão do satélite/mapa"
                    @click="toggleSatelite()"
                >
                    <ImageIcon />
                </button>
            </div>

            <div class="d-flex justify-content-start flex-column controls-close-left align-items-center">
                <button
                    class="btn rounded-circle p-1"
                    data-toggle="tooltip"
                    data-placement="right"
                    title="Exibir/ocultar curva de vulnerabilidade"
                    @click="toggleCurve()"
                >
                    <ZapOffIcon v-if="curveHidden && !zoom" />
                    <ZapIcon v-else-if="!zoom" />
                </button>
            </div>
        </div>
        <div class="text-center titulo-mapa py-1">Mapa Geral {{ zoom !== 0 ? `/ Zoom ${String(zoom).padStart(2, '0')}` : ''}}</div>
        <div class="d-flex justify-content-start flex-column controls">
            <button
                class="btn rounded-circle p-1 max"
                data-toggle="tooltip"
                data-placement="left"
                title="Colocar em tela cheia"
                @click="max"
            >
                <MaximizeIcon />
            </button>
            <button
                class="btn rounded-circle p-1 min"
                data-toggle="tooltip"
                data-placement="left"
                title="Sair da tela cheia"
                @click="min"
            >
                <MinimizeIcon />
            </button>
            <button
                class="btn rounded-circle p-1"
                data-toggle="tooltip"
                data-placement="left"
                title="Mostrar log de eventos"
                v-show="isHidden"
                @click="$emit('show')"
            >
                <ArrowLeftCircleIcon />
            </button>
            <button
                class="btn rounded-circle p-1"
                data-toggle="tooltip"
                data-placement="left"
                title="Ocultar log de eventos"
                v-show="!isHidden"
                @click="$emit('hide')"
            >
                <ArrowRightCircleIcon />
            </button>
             <button
                class="btn rounded-circle p-1"
                data-toggle="tooltip"
                data-placement="left"
                title="Exibir paineis de informação"
                @click="$emit('info')"
            >
                <InfoIcon />
            </button>
             <button
                class="btn rounded-circle p-1"
                data-toggle="tooltip"
                data-placement="left"
                title="Exibir/ocultar texto no mapa"
                @click="toggleText()"
            >
                <TypeIcon />
            </button>
        </div>
        <div class="logos d-flex align-items-center">
            <img src="@/assets/img/LOGO_TCS_AZUL.png" />
            <div class="divisor"></div>
            <img src="@/assets/img/usiminas-logo-1.png" />
        </div>
    </div>
</template>

<script>
let mapInstance;
const resizeMapFn = () => {
    if (!mapInstance) return;
    mapInstance.resize();
    mapInstance.fit();
    mapInstance.center();
};

const textStyle = {
    fill: 'black',
    fontSize: '14px',
    fontWeight: 'normal',
    fontFamily: 'Arial sans-serif',
}
function createCircle(x, y, up, down, colors = ['white'], scale=1, title) {
    const r = 18.5 * scale;
    textStyle.fontSize = (14 * scale) + 'px';

    if (!colors) colors = ['white'];

    let radius = r;
    const children = colors.map((color) => {
        if (radius < 0) return;
        const circle = hSvg('circle', {
                r: radius,
                cx: x,
                cy: y,
                style: {
                    fill: color || 'white',
                    stroke: '#28166f',
                    strokeWidth: 2 * scale,
                },
                class: 'piscando',
            });
        radius -= 10;

        return circle;
    }).filter((c) => c);

    return hSvg('g', {}, [
        hSvg('title', null, [title]),
        hSvg('circle', {
            r,
            cx: x,
            cy: y,
            style: {
                fill: '#ffffff',
                stroke: '#28166f',
                strokeWidth: 2 * scale,
            },
        }),
        ...children,
        hSvg('line', {
            x1: x - r,
            y1: y,
            x2: x + r,
            y2: y,
            style: {
                stroke: '#28166f',
                strokeWidth: 2 * scale,
            }
        }),
        hSvg('g', { class: 'textos'}, [
            hSvg('text', {
                x,
                y: y - 3 * scale,
                'text-anchor': 'middle',
                style: textStyle,
            }, [up]),
            hSvg('text', {
                x,
                y: y + 13 * scale,
                'text-anchor': 'middle',
                style: textStyle,
            }, [down]),
        ]),
    ]);
}

function bubbleExternalMouseMove (externalObject){
    externalObject.contentWindow.addEventListener('mousemove', function (ev) {
        var boundingClientRect = externalObject.getBoundingClientRect();

        var evt = new CustomEvent('mousemove', { bubbles: true, cancelable: false });
        evt.x = ev.x + boundingClientRect.left;
        evt.y = ev.y + boundingClientRect.top;
        externalObject.dispatchEvent(evt);
    });
}
import svgPanZoom from 'svg-pan-zoom';
import { mapState } from 'vuex'
import { hSvg } from '@/helpers/h';
import { matrixDot } from '@/helpers/matrix';

const transformation = {
    1:  { tx: 0, ty: 165, ts: 0.2708 },
    2:  { tx: 8, ty: 346, ts: 0.3052 },
    3:  { tx: 485, ty: 189, ts: 0.1937 },
    4:  { tx: 498, ty: 302, ts: 0.1906 },
    5:  { tx: 387, ty: 498, ts: 0.2461 },
    6:  { tx: 725, ty: 183, ts: 0.1958 },
    7:  { tx: 804, ty: 267, ts: 0.2010 },
    8:  { tx: 844, ty: 491, ts: 0.20 },
    9:  { tx: 691, ty: 646, ts: 0.20 },
    10: { tx: 983, ty: 179, ts: 0.24 },
    11: { tx: 998, ty: 275, ts: 0.21 },
    12: { tx: 1098, ty: 489, ts: 0.22 },
    13: { tx: 1209, ty: 283, ts: 0.22 },
    14: { tx: 1262, ty: 373, ts: 0.32 },
    15: { tx: 1431, ty: 540, ts: 0.28 },
}

export default {
    props: {
        concentradores: {
            type: Array,
            default: () => []
        },
        isHidden: {
            type: Boolean,
            defalse: false,
        },
    },
    data () {
        return {
            mainUrl: require('@/assets/img/mapa-texto.svg'),
            zoom1Url: '/zooms/1.svg',
            zoom2Url: '/zooms/2.svg',
            zoom3Url: '/zooms/3.svg',
            zoom4Url: '/zooms/4.svg',
            zoom5Url: '/zooms/5.svg',
            zoom6Url: '/zooms/6.svg',
            zoom7Url: '/zooms/7.svg',
            zoom8Url: '/zooms/8.svg',
            zoom9Url: '/zooms/9.svg',
            zoom10Url: '/zooms/10.svg',
            zoom11Url: '/zooms/11.svg',
            zoom12Url: '/zooms/12.svg',
            zoom13Url: '/zooms/13.svg',
            zoom14Url: '/zooms/14.svg',
            zoom15Url: '/zooms/15.svg',
            zoom: 0,
            circulosConcentradores: [],
            isSatelite: false,
            curveHidden: true,

            userScale: +(localStorage.getItem('utilities/sinotico/escala')) || 1,
        }
    },
    computed: {
        ...mapState(['sideBarWidth']),
        mapaUrl () {
            switch (this.zoom) {
                case 0:  return this.mainUrl;
                case 1:  return this.zoom1Url;
                case 2:  return this.zoom2Url;
                case 3:  return this.zoom3Url;
                case 4:  return this.zoom4Url;
                case 5:  return this.zoom5Url;
                case 6:  return this.zoom6Url;
                case 7:  return this.zoom7Url;
                case 8:  return this.zoom8Url;
                case 9:  return this.zoom9Url;
                case 10:  return this.zoom10Url;
                case 11:  return this.zoom11Url;
                case 12:  return this.zoom12Url;
                case 13:  return this.zoom13Url;
                case 14:  return this.zoom14Url;
                case 15:  return this.zoom15Url;
                default: return this.mainUrl;
            }
        }
    },
    watch: {
        sideBarWidth: 'resize',
        concentradores: 'showConcentradores',
        userScale (nv) {
            if (!+nv) return;
            localStorage.setItem('utilities/sinotico/escala', nv);
            this.showConcentradores();
        },
    },
    mounted () {
        window.addEventListener('resize', resizeMapFn);
        bubbleExternalMouseMove(this.$refs.mapa);
    },
    beforeDestroy () {
        window.removeEventListener('resize', resizeMapFn);
    },
    methods: {
        onClick (container, elementId, nZoom) {
            let el = container.getElementById(elementId);
            if (!el) return;
            el.style.cursor = 'pointer';
            el.addEventListener('mousedown', ev => {
                if (ev.button !== 0) return;
                const {x: x0, y: y0} = ev;
                const t = Date.now();
                const onUp = ev2 => {
                    const {x, y} = ev2;
                    const d = Math.sqrt((x-x0)**2+(y-y0)**2);
                    if (Date.now() - t < 500 && d < 10) {
                        this.zoom = nZoom;
                        this.$emit('zoom', nZoom);
                    }
                    container.removeEventListener('mouseup', onUp);
                }
                container.addEventListener('mouseup', onUp);
            });
        },
        onLoad () {
            this.circulosConcentradores = [];
            mapInstance = svgPanZoom('#mapa-sinotico-zoom', {
                zoomEnabled: true,
                controlIconsEnabled: true,
                mouseWheelZoomEnabled: true,
                preventMouseEventsDefault: false,
            });
            this.showConcentradores();
            this.loadZoom();
            if(!this.zoom) this.applyCurveVisibility();
        },
        loadZoom () {
            let m = document.getElementById('mapa-sinotico-zoom').contentDocument;
            const el = m.getElementsByClassName("svg-pan-zoom_viewport")[0];
            el.style.userSelect = 'none';
            m.firstElementChild.style.backgroundColor = '#f5f5f5';
            this.applyTextVisibility();
            el.appendChild(hSvg('style', null, [`
            svg {
                background: white;
            }
            @keyframes piscar {
                from {
                    opacity: 1;
                }
                to {
                    opacity: 0;
                }
            }
            .piscando {
                animation: 1s ease-in-out infinite alternate piscar;
            }
            .textos {
               /* mix-blend-mode: difference; */
            }
            `]));
            if (this.zoom !== 0) return;
            this.onClick(m, 'zoom01', 1);
            this.onClick(m, 'zoom02', 2);
            this.onClick(m, 'zoom03', 3);
            this.onClick(m, 'zoom04', 4);
            this.onClick(m, 'zoom05', 5);
            this.onClick(m, 'zoom06', 6);
            this.onClick(m, 'zoom07', 7);
            this.onClick(m, 'zoom08', 8);
            this.onClick(m, 'zoom09', 9);
            this.onClick(m, 'zoom10', 10);
            this.onClick(m, 'zoom11', 11);
            this.onClick(m, 'zoom12', 12);
            this.onClick(m, 'zoom13', 13);
            this.onClick(m, 'zoom14', 14);
            this.onClick(m, 'zoom15', 15);
        },
        max() {
            this.$parent.$el.requestFullscreen({
                navigationUI: 'hide',
            }).then(this.resize);
        },
        min() {
            document.exitFullscreen().then(this.resize);
        },
        calculaPosicaoConcentradoresAlarmes(x, y, nZoom) {
            if(this.zoom !== 0) return { x, y };

            let { tx, ty, ts } = transformation[nZoom] || { tx: 0, ty: 0, ts: 1 };
            const matrix = [
                [ts, 0, tx],
                [0, ts, ty],
                [0, 0, 1],
            ];
            const point = [[x], [y], [1]];
            const [[nx], [ny]] = matrixDot(matrix, point);
            return { x: nx, y: ny }
        },
        toggleSatelite() {
            this.isSatelite = !this.isSatelite;

            if (this.isSatelite) {
                this.mainUrl = require('@/assets/img/mapa-texto-satelite.svg');
                this.zoom1Url = '/zooms/satelite/1-satelite.svg';
                this.zoom2Url = '/zooms/satelite/2-satelite.svg';
                this.zoom3Url = '/zooms/satelite/3-satelite.svg';
                this.zoom4Url = '/zooms/satelite/4-satelite.svg';
                this.zoom5Url = '/zooms/satelite/5-satelite.svg';
                this.zoom6Url = '/zooms/satelite/6-satelite.svg';
                this.zoom7Url = '/zooms/satelite/7-satelite.svg';
                this.zoom8Url = '/zooms/satelite/8-satelite.svg';
                this.zoom9Url = '/zooms/satelite/9-satelite.svg';
                this.zoom10Url = '/zooms/satelite/10-satelite.svg';
                this.zoom11Url = '/zooms/satelite/11-satelite.svg';
                this.zoom12Url = '/zooms/satelite/12-satelite.svg';
                this.zoom13Url = '/zooms/satelite/13-satelite.svg';
                this.zoom14Url = '/zooms/satelite/14-satelite.svg';
                this.zoom15Url = '/zooms/satelite/15-satelite.svg';
            } else {
                this.mainUrl = require('@/assets/img/mapa-texto.svg');
                this.zoom1Url = '/zooms/1.svg';
                this.zoom2Url = '/zooms/2.svg';
                this.zoom3Url = '/zooms/3.svg';
                this.zoom4Url = '/zooms/4.svg';
                this.zoom5Url = '/zooms/5.svg';
                this.zoom6Url = '/zooms/6.svg';
                this.zoom7Url = '/zooms/7.svg';
                this.zoom8Url = '/zooms/8.svg';
                this.zoom9Url = '/zooms/9.svg';
                this.zoom10Url = '/zooms/10.svg';
                this.zoom11Url = '/zooms/11.svg';
                this.zoom12Url = '/zooms/12.svg';
                this.zoom13Url = '/zooms/13.svg';
                this.zoom14Url = '/zooms/14.svg';
                this.zoom15Url = '/zooms/15.svg';
            }
        },
        toggleText() {
            this.textHidden = !this.textHidden;
            this.applyTextVisibility();
        },
        applyTextVisibility () {
            this.$refs.mapa.contentDocument.getElementById('layer1').style.visibility = this.textHidden ? 'hidden' : 'visible';
        },
        toggleCurve() {
            this.curveHidden = !this.curveHidden;
            this.applyCurveVisibility();
        },
        applyCurveVisibility () {
            this.$refs.mapa.contentDocument.getElementById('layer6').style.visibility = this.curveHidden ? 'hidden' : 'visible';
        },
        resize() {
            setTimeout(resizeMapFn);
            setTimeout(resizeMapFn, 1000);
        },
        showConcentradores() {
            let m = this.$refs.mapa.contentDocument;
            const el = m.getElementsByClassName("svg-pan-zoom_viewport")[0];
            this.circulosConcentradores.map(c => el.removeChild(c));
            this.circulosConcentradores = [];
            const nZoomNullPosition = { x: 100, y: 120 };
            let hasNullNZoomLine = false;

            for (let c of this.concentradores) {
                if(!c.marcadorX && this.zoom !== 0) continue;
                let marcador;
                let escala = this.zoom === 0 ? 0.65 : 1.2;
                //posiciona o concentrador fora do mapa se ele não possuir uma area associada
                if ((c.nZoom === null || c.marcadorX === null) && this.zoom === 0) {
                    if (!hasNullNZoomLine) {
                        hasNullNZoomLine = true;
                        el?.appendChild(hSvg('line', {
                            x1: 79,
                            x2: 1719,
                            y1: 160,
                            y2: 160,
                            style: {
                                stroke: '#cccccc',
                                strokeWidth: 2,
                            }
                        }));
                    }
                    let x = nZoomNullPosition.x;
                    let y = nZoomNullPosition.y;
                    marcador = { x, y };
                    nZoomNullPosition.y = nZoomNullPosition.x >= 1700 ? nZoomNullPosition.y-50 : nZoomNullPosition.y;
                    nZoomNullPosition.x = nZoomNullPosition.x >= 1700 ? 100 : nZoomNullPosition.x+50;
                    escala = 1;
                } else
                    marcador = this.calculaPosicaoConcentradoresAlarmes(c.marcadorX, c.marcadorY, c.nZoom);

                let circle = createCircle(marcador.x, marcador.y, c.nome.substr(0, 2).toUpperCase(),
                                        c.nome.substr(2).substr(c.nome.length-4).toUpperCase(),
                                        c.cor ? c.cor : null, escala * this.userScale, `${c.nome} (${c.tipico})`);
                circle.style.cursor = 'pointer';
                circle.onclick = () => {
                    if (this.zoom === 0) {
                        if (c.nZoom && c.marcadorX !== null) {
                            this.zoom = c.nZoom;
                            this.$emit('zoom',c.nZoom);
                        } else this.$emit('showAlarmsInfo', c);
                    } else {
                        this.$emit('showAlarmsInfo', c);
                    }
                }
                el?.appendChild(circle)
                this.circulosConcentradores.push(circle);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.map-container {
    position: relative;
    height: 100%;
    overflow: hidden;

    object {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }
    .controls {
        position: absolute;
        right: 0;
        top: 0;
    }
    .controls-left {
        position: absolute;
        left: 0;
        top: 0;
    }
    .controls-far-left {
        position: absolute;
        left: 48px;
        top: 0;
    }
    .controls-close-left {
        position: absolute;
        left: 94px;
        top: 0;
    }
    .titulo-mapa {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        pointer-events: none;
        opacity: 0.8;
        line-height: 24px;
        font-weight: 700;
    }
}
.min {
    display: none;
}
.max {
    display: block;
}
:fullscreen {
    .min {
        display: block;
    }
    .max {
        display: none;
    }
}
.logos {
    position: absolute;
    bottom: 0;
    left: 0;
    pointer-events: none;
    opacity: 0.5;

    img {
        height: 4vh;
        margin: 0 1vh;
        max-height: 50px;
    }

    .divisor {
        height: 4vh;
        max-height: 50px;
        width: 0px;
        border: 1px solid black;
    }

}
.escala {
    opacity: 0.35;

    input {
        width: 36px;
        display: none;

        @media screen and (max-width: 790px) {
            width: 8px;
            display: block;
        }
    }

    &:hover, &:focus {
        opacity: 1;

        input {
            display: block;
        }
    }
}

</style>
